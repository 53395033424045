import React, {useState} from 'react'
import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  const [nav, setNav] = useState(false)

  const handleNav = () => {
    setNav(!nav)
  }
  return (
    <div className='active px-4 sm:px-6 lg:px-8 mx-auto max-w-[1500px] h-[8vh]'>
        <div class="flex items-center justify-between h-16 lg:h-20 bg-[#223]/30 rounded-b-3xl shadow-lg px-4">
            <h1 className='text-2xl font-bold sky-color ml-4'>RainChoi</h1>
            <ul className='hidden md:flex'>
                <li className='mr-4'><NavLink to="/">Home</NavLink></li>
                <li className='mr-4'><NavLink to="/about" activeClassName="text-black">About</NavLink></li>
                <li className='mr-4'><NavLink to="/experience" activeClassName="text-black">Experience</NavLink></li>
                {/* <li className='mr-4'><NavLink to="/blog" activeClassName="text-black">Blog</NavLink></li> */}
            </ul>
            <div onClick={handleNav} className='md:hidden text-white z-40'>
                {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>}
            </div>
            <div className={`${nav ? 'z-30 text-gray-300 fixed h-[50%] left-0 top-0 w-full bg-[#202121] ease-in-out duration-500' : 'fixed left-[-100%]'} md:hidden`}>
                <h1 className='text-3xl font-bold text-white m-4'>RainChoi</h1>
                <ul className='p-8 text-4xl ml-20'>
                    <li className='mr-4'><NavLink to="/">Home</NavLink></li>
                    <li className='mr-4'><NavLink to="/about" activeClassName="text-black">About</NavLink></li>
                    <li className='mr-4'><NavLink to="/experience" activeClassName="text-black">Experience</NavLink></li>
                    {/* <li className='mr-4'><NavLink to="/blog" activeClassName="text-black">Blog</NavLink></li> */}
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Navbar;
