import React, { useState } from 'react';

const BlogCard = ({ title, date, content }) => {
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div className='w-full max-w-4xl px-10 py-8 mb-6 bg-white text-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out'>
            <h2 className="text-2xl font-semibold">{title}</h2>
            <p className="text-sm text-gray-500">{formatDate(date)}</p>
            <p className="mt-2 text-lg">{content}</p>
        </div>
    );
};

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className='flex justify-center mt-6'>
            <ul className='flex list-none gap-2'>
                {pageNumbers.map(number => (
                    <li key={number}>
                        <button
                            onClick={() => paginate(number)}
                            className='px-4 py-2 bg-cyan-300 text-white rounded hover:bg-cyan-400'
                        >
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

const BlogList = () => {
    const blogs = [
        { id: 1, title: 'Blog 1', content: 'Lorem ipsum dolor sit amet.', date: '2023-04-30T12:00:00.000Z' },
        { id: 2, title: 'Blog 2', content: 'Consectetur adipiscing elit.', date: '2023-05-01T15:30:00.000Z' },
        { id: 3, title: 'Blog 3', content: 'Praesent vel semper nunc.', date: '2023-05-02T14:45:00.000Z' },
        { id: 4, title: 'Blog 4', content: 'Vestibulum nec nulla sit amet.', date: '2023-05-03T10:00:00.000Z' },
        { id: 5, title: 'Blog 5', content: 'Sed in urna in ligula.', date: '2023-05-04T17:00:00.000Z' },
        { id: 6, title: 'Blog 6', content: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.', date: '2023-05-05T08:30:00.000Z' },
        { id: 7, title: 'Blog 7', content: 'Nullam nec nunc nec nunc.', date: '2023-05-06T11:15:00.000Z' },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [blogsPerPage] = useState(5);

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='relative pt-16 pb-10 sm:pt-16 sm:pb-16 lg:pb-18 overflow-auto h-[92vh]'>
            <div className="p-7 mx-auto text-center">
                <h1 className="text-4xl sky-color font-bold leading-tight sm:text-5xl md:text-6xl">Welcome to My Personal Blog!</h1>
                <p className="text-xl text-gray-300 mt-4">Explore my latest posts below.</p>
            </div>
            <div className="flex flex-col items-center">
                {currentBlogs.map(blog => (
                    <BlogCard key={blog.id} title={blog.title} date={blog.date} content={blog.content} />
                ))}
            </div>
            <Pagination postsPerPage={blogsPerPage} totalPosts={blogs.length} paginate={paginate} />
        </div>
    );
};

export default BlogList;
