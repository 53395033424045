import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";

function Experience() {
  return (
    <div className="relative pt-16 pb-10 sm:pt-16 sm:pb-16 lg:pb-18 h-[92vh] overflow-auto">
      <VerticalTimeline className="text-gray-800" lineColor="#3e497a">
        {/* Education at Pui Ching Middle School */}
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2005 - 2019"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="text-xl font-bold">
            <a href="https://m.puiching.edu.mo/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
              Pui Ching Middle School
            </a>, Macau
          </h3>
          <p className="text-base">High School Diploma</p>
        </VerticalTimelineElement>

        {/* Education at Tsinghua University */}
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2019 - 2023"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="text-xl font-bold">
            <a href="https://www.tsinghua.edu.cn/en/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
              Tsinghua University
            </a>, Beijing, China
          </h3>

          <h4 className="font-semibold">Bachelor's Degree</h4>

          <p className="text-base">Automation Engineering</p>
        </VerticalTimelineElement>

        {/* Work at Shanghai AI Lab */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2022"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="text-xl font-bold">
            <a href="https://shlab.org.cn/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
              Shanghai Artificial Intelligence Laboratory
            </a>, Beijing, China
          </h3>
          <h4 className="font-semibold">Research Intern</h4>

          <p className="text-base">Completed the debugging of Temporal Action Localization part of INTERN 2.0.</p>
          
          <a href="https://github.com/OpenGVLab/InternVideo/tree/main/InternVideo1/Downstream/Temporal-Action-Localization" 
             className="text-blue-600 hover:underline hover:text-blue-800" 
             target="_blank" 
             rel="noopener noreferrer">
            GitHub Link
          </a>
          <br />
        </VerticalTimelineElement>

        {/* Education at University of Waterloo */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2023 - present"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="text-xl font-bold">
            <a href="https://uwaterloo.ca/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
              University of Waterloo
            </a>, Waterloo, Canada
          </h3>

          <h4 className="font-semibold">Master's Degree</h4>

          <p className="text-base">
            Electrical and Computer Engineering
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
