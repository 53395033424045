import React from 'react';
import heroImage from '../assets/profile.jpg';

const About = () => {
  return (
    <div className='relative pt-16 pb-10 sm:pt-16 sm:pb-16 lg:pb-18 overflow-hidden h-[92vh]'>
      <div className='py-16 mb-6 overflow-hidden md:pt-0 sm:pt-16 2xl:pt-16 relative bg-black/40 max-w-[1300px] mx-auto'>
        <div className="p-7 mx-auto flex flex-col lg:flex-row">
          <div className="flex-1">
            <h2 className="text-3xl font-bold leading-tight text-white sm:text-4xl md:text-5xl lg:text-6xl">
              Hello, I'm <span className="italic">Rain Choi</span>
            </h2>
            <p className="max-w-lg mt-3 text-xl leading-relaxed text-gray-400 md:mt-8">
              Hi there! I'm a Computer Engineering student at the University of Waterloo, passionate about software development, machine learning, and AI. I'm always eager to learn and grow.
            </p>
            <p className="max-w-lg mt-3 text-xl leading-relaxed text-gray-400 md:mt-8">
              I am originally born and raised in Macau SAR, China. I am currently studying in Canada. I am fluent in English, Cantonese, and Mandarin. I have also been learning Japanese for a few years.

              When I'm not coding, I enjoy watching the NBA, playing poker and making coffee. I'm a Golden State Warriors fan.
            </p>
          </div>
          <img 
            src={heroImage} 
            alt="Profile photo of Rain Choi" 
            className="flex-1 md:absolute top-[10%] right-[10%] sm:bottom-[5%] max-w-[240px] md:max-w-[400px] h-auto"
          />
        </div>
      </div>
    </div>
  )
}

export default About;
