import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Experience from './components/Experience';
import About from './components/About';
import BlogList from './components/BlogList';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
      <BrowserRouter>
        <div>
          <Navbar />
          <Routes>
            <Route path='/' element={<Hero />} />
            <Route path='/experience' element={<Experience />} />
            <Route path='/about' element={<About />} />
            <Route path='/blog' element={<BlogList />} />
          </Routes>
        </div>
      </BrowserRouter>
  );
}

export default App;
