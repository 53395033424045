import React from 'react';
// import { TypeAnimation } from 'react-type-animation';
import heroImage from '../assets/image.png';
import noteBookM from '../assets/notebookM.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import ResumeIcon from '@mui/icons-material/Description';

const Hero = () => {
  return (
    <div className="relative pt-16 pb-10 sm:pt-16 sm:pb-16 lg:pb-18 overflow-auto h-[92vh]">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 relative z-20">
        <div class="max-w-2xl mx-auto text-center">
            <h1 class="text-4xl font-extrabold tracking-tight primary-color sm:text-5xl md:text-6xl lg:text-7xl">
                Hello, I'm Rain Choi
            </h1>
            <p class="mt-5 text-base text-white sm:text-xl">
                A Computer Engineering student at the University of Waterloo.
            </p>
            <a href="https://www.linkedin.com/in/rain-choi/" target="_blank" rel="noreferrer" class="inline-block mt-8 mr-4 px-4 py-2 text-lg font-semibold text-white bg-[#0077B5] rounded-lg hover:bg-[#005f8d]">
                <LinkedInIcon fontSize="large" />
            </a>
            <a href="https://www.github.com/RainCCH" target="_blank" rel="noreferrer" class="inline-block mt-8 mr-4 px-4 py-2 text-lg font-semibold text-white bg-[#333] rounded-lg hover:bg-[#000]">
                <GitHubIcon fontSize="large" />
            </a>
            <a href="https://www.instagram.com/rainchoi.c.h/" target="_blank" rel="noreferrer" class="inline-block mt-8 mr-4 px-4 py-2 text-lg font-semibold text-white bg-[#E1306C] rounded-lg hover:bg-[#b82358]">
                <InstagramIcon fontSize="large" />
            </a>
            <a href="mailto:chchoi@uwaterloo.ca" target="_blank" rel="noreferrer" class="inline-block mt-8 mr-4 px-4 py-2 text-lg font-semibold text-white bg-[#D14836] rounded-lg hover:bg-[#a63a2c]">
                <EmailIcon fontSize="large" />
            </a>
            <a href={`${process.env.PUBLIC_URL}/resume.pdf`} target="_blank" rel="noreferrer" class="inline-block mt-8 px-4 py-2 text-lg font-semibold text-white bg-[#0077B5] rounded-lg hover:bg-[#005f8d]">
                <ResumeIcon fontSize="large" />
            </a>
            <p className="mt-6 text-4xl sky-color" style={{ fontFamily: 'Lobster, cursive' }}>
              "What doesn't kill you makes you stronger."
            </p>
        </div>
      </div>
      <div class="relative bottom-0 z-10 flex mx-auto justify-center">
        {/* <img src={heroImage} class="left-[-20px] top-[-20px] sm:left-20 sm:top-[-200px] absolute block w-auto h-[150px] sm:h-[500px]" /> */}
        <img className="absolute sm:relative w-auto bottom-[-90px] right-[-40px] sm:bottom-0 sm:right-0 h-[380px] sm:h-[380px] top-[100px] sm:top-[100px]" src={noteBookM} />
        <img className="absolute w-auto top-[200px] sm:top-[200px] right-[-50px] sm:right-[-50px] h-auto sm:w-[500px]" src={heroImage} />
      </div>
    </div>
  );
}

export default Hero;
